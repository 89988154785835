import Vue from "vue";
import Router from "vue-router";
import SlideVerify from "../page/verify/SlideVerify"
// 要告诉 vue 使用 vueRouter
Vue.use(Router)
const router = new Router({
    routes: [
        {
            path: "/slideverify/:scene",
            component: SlideVerify,
            name: 'slideverify',
            meta: {
                title: '滑动验证'
            }
        },
        {
            path: "*",
            redirect: "/slideverify/:scene"
        }
    ]

})
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};
export default router



<!--滑动验证-->
<template>
  <div id="content">
    <div id="__nc" class="center">
      <div id="nc"></div>
    </div>
  </div>

</template>

<script>
import NoCaptcha from 'NoCaptcha'

export default {
  data() {
    return {
      scene: 'register',//场景标识 ，默认注册
      app_key: 'FFFF0N00000000009A1E'
    }
  },
  methods: {
    init() {
      let nc_token = [this.app_key, (new Date()).getTime(), Math.random()].join(':');
      let nc = NoCaptcha.init({
        //声明滑动验证需要渲染的目标元素ID。
        renderTo: '#nc',
        //应用类型标识。它和使用场景标识（scene字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
        appkey: this.app_key,
        //使用场景标识。它和应用类型标识（appkey字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的scene值，请务必正确填写。
        scene: 'nc_message_h5',
        //滑动验证码的主键，请勿将该字段定义为固定值。确保每个用户每次打开页面时，其token值都是不同的。系统默认的格式为：”您的appkey”+”时间戳”+”随机数”。
        token: nc_token,
        //业务键字段，可为空。为便于线上问题的排查，建议您按照线上问题定位文档中推荐的方法配置该字段值。
        trans: {"key1": "code0"},
        //语言，默认值为cn（中文）。HTML5应用类型默认支持简体中文、繁体中文、英文语言。
        language: "cn",
        //内部网络请求的超时时间。一般情况建议保持默认值（10000ms）。
        timeout: 10000,
        //允许服务器超时重复次数，默认5次。
        retryTimes: 5,
        errorTimes: 5,
        //验证通过后，验证码组件是否自动隐藏，默认不隐藏（false）。
        bannerHidden: false,
        //是否默认不渲染，默认值false。当设置为true时，不自动渲染，需要自行调用show方法进行渲染。
        initHidden: false,
        //前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将请求标识（token）、会话ID（sessionid）、签名串（sig）字段记录下来，随业务请求一同发送至您的服务端调用验签。
        callback: function (data) {
          if (window.App) {
            window.App.getSlideData(nc_token, data.csessionid, data.sig)
          }
        },
        error: function (s) {
          console.log(s)
        }
      });

      NoCaptcha.setEnabled(true);
      nc.reset();

      //用于配置滑动验证的自定义文案。详细信息，请参见自定义文案与多语言文档。
      NoCaptcha.upLang('cn', {
        //加载状态提示。
        'LOADING': "加载中...",
        //等待滑动状态提示。
        'SLIDER_LABEL': "请向右滑动验证",
        //验证通过状态提示。
        'CHECK_Y': "验证通过",
        //验证失败触发拦截状态提示。
        'ERROR_TITLE': "非常抱歉，出错了...",
        'CHECK_N': "验证未通过", //准备唤醒二次验证
        'OVERLAY_INFORM': "经检测你当前操作环境存在风险，请输入验证码",//二次验证
        'TIPS_TITLE': "验证码错误，请重新输入"//验证码输错时的提示
      });
    }
  },
  mounted() {
    this.scene = this.$route.params.scene;
    this.init();
  },


}
</script>

<style lang="scss">

/* 滑动条高度、边框、背景色等 */
._nc .stage1 .slider {
  height: 52px;
  border-radius: 3px;
  box-shadow: 0 0 3px #999;
  background-color: #ddd !important;
}

/* 滑动条 */
._nc .stage1 .track div {
  border-radius: 3px;
  color: #fff;
}

/* 滑动条背景色-正常 */
._nc .stage1 .bg-green {
  background-color: #2283e0 !important;
}

/* 滑动条背景色-失败 */
._nc .stage1 .bg-red {
  background-color: #F44336;
}

._nc .icon-ok {
  font-size: 30px;
  color: #2283e0;
}

._nc .stage1 .icon {
  color: #2283e0;
}

.menu .refresh {
  position: static !important;
}

._nc .stage3 .menu.nc-sep {
  height: 25px;
  display: none;
}

._nc .stage3 .menu {
  position: static !important;
}

._nc .stage3 .menu.feedback {
  display: none;
}

._nc .stage3 .menu .label {
  top: -6px;
}

.center {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

#content {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
